body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  height: 100%;
}


html, body {
  width:  100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /*overflow: hidden;*/
  background-color: #f0f0f0;
}

.canvas {
  background-color: #ccc;
  display: block;
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*width: 50%;*/
  /*height: 50%;*/
}

.wrapper {
  display: flex;
}

select, option {

}

.frame
{
  position: absolute;
  left:10%;
  top:10%;
  width:80%;
  height:80%;;
  background:blue url('http://img1.123freevectors.com/wp-content/uploads/objects_big/122_Vector_Compass.png') no-repeat center center;
  display:flex;
  align-content: center;
  align-items: center;

  font-size: 50px;
  font-family: Arial, Helvetica, sans-serif;

  color: #fff;
}

p {
  margin: 0 auto;
  text-align: center;
  width: 80%;
}

body {
  touch-action: none;
}

#TmSelect, #mySelect{
  width: inherit;
}

/*select {*/
/*  margin: 0;*/
/*  width: 100%;*/
/*}*/

/*option:hover {*/
/*  background: lightblue !important;*/
/*}*/

/*select, option:checked {*/
/*  font-weight: bold;*/
/*}*/
